import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase';
import babelPolyfill from 'babel-polyfill'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
// const Dashboard = () => import('@/views/Dashboard')
const Dashboard = () => import ('@/views/dashboard/DashboardUser');
const DashboardProvider = () => import ('@/views/providers/dashboard/DashboardProvider');

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Switches = () => import('@/views/base/Switches')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tables = () => import('@/views/tables/Tables')
const AdvancedTables = () => import('@/views/tables/AdvancedTables')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')
const LoadingButtons = () => import('@/views/buttons/LoadingButtons')

// Views - Editors
const TextEditors = () => import('@/views/editors/TextEditors')
const CodeEditors = () => import('@/views/editors/CodeEditors')

// Views - Forms
const BasicForms = () => import('@/views/forms/Forms')
const AdvancedForms = () => import('@/views/forms/AdvancedForms')
const ValidationForms = () => import('@/views/forms/ValidationForms')
const MultiSelect = () => import('@/views/forms/MultiSelect')

// Views GoogleMaps
const GoogleMaps = () => import('@/views/GoogleMaps')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')
const Toaster = () => import('@/views/notifications/Toaster')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const Main = () => import ('@/views/pages/Main')
const TermsDashboard = () => import('@/views/pages/TermsDashboard');
const TermsAndConditions = () => import ('@/views/pages/TermsAndConditions')
const TermsAndConditionsProvider = () => import ('@/views/pages/TermsAndConditionsProvider')
const TermsPersonalData = () => import ('@/views/pages/TermsPersonalData');
const TermsAndConditionsTag = () => import ('@/views/pages/TermsAndConditionsTag')
const QRGenerator = () => import ('@/views/pages/QRGenerator')
const PassRecovery = () => import ('@/views/pages/PassRecovery');
const Support = () => import ('@/views/pages/Support');
const WalkerApply = () => import('@/views/pages/Walkers');
//Pages - Payments
const ManualPayment = () => import('@/views/pages/payment/Clients');
const ManualPaymentCreate = () => import('@/views/pages/payment/Create');

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
const UserCreate = () => import ('@/views/users/Create');
const UserProfile = () => import ('@/views/users/Profile');

// Views - Providers
const ProviderCreate = () => import ('@/views/providers/users/Create');
const WalkerServices = () => import('@/views/providers/dashboard/WalkerServices')
const ProviderCalendar = () => import ('@/views/providers/calendar/Calendar');
const ProviderCalendarList = () => import ('@/views/providers/calendar/List');
const ProviderSchedule = () => import ('@/views/providers/schedule/ProviderSchedule');


// Pets
// const PetsCreate = () => import('@/views/pets/Create')
const PetsIndex = () => import('@/views/pets/Index')
const PetsForm = () => import('@/views/pets/Form')

// Plugins
const Draggable = () => import('@/views/plugins/Draggable')
const Calendar = () => import('@/views/plugins/Calendar')
const Spinners = () => import('@/views/plugins/Spinners')

// Apps -> Invoice
const Invoice = () => import('@/views/apps/invoicing/Invoice')

// Apps -> Email
const EmailApp = () => import('@/views/apps/email/EmailApp')
const Compose = () => import('@/views/apps/email/Compose')
const Inbox = () => import('@/views/apps/email/Inbox')
const Message = () => import('@/views/apps/email/Message')

// Public
const PetProfileQR = () => import('@/views/public/PetProfile')

// Services
const ServiceProfile = () => import ('@/views/services/Profile');
const Schedule = () => import('@/views/services/Schedule');
const Payments = () => import('@/views/services/Payments');
const TrackingGPS = () => import('@/views/services/TrackingGPS');
const Buy = () => import('@/views/services/Buy');
const PaymentConfirmation = () => import('@/views/services/PaymentConfirmation');
const ServiceQualification = () => import('@/views/comments/qualifyWalker');
const ServicesIndex = () => import('@/views/services/Index');
const ServiceQuotation = () => import ('@/views/services/quotation/Main')

//Admin
const AdminDashboard = () => import ('@/views/admin/Dashboard');
const AdminExecution = () => import ('@/views/admin/Execution');
const AdminClients = () => import ('@/views/admin/Clients');
const AdminClientsActivity = () => import ('@/views/admin/ClientsActivity');
const AdminProvidersDistance = () => import ('@/views/admin/ProvidersDistance');
const AdminCalendar = () => import ('@/views/admin/admin_info/Calendar');
const FixOrdersCount = () => import ('@/views/admin/owner/FixOrdersCount')

//Financial
const FinancialDashboard = () => import ('@/views/financial/Dashboard');
const FinancialPendingPayments = () => import ('@/views/financial/payment/Pending');
const FinancialPayments = () => import ('@/views/financial/payment/Clients');
const FinancialPaymentCreate = () => import('@/views/financial/payment/Create');
const FinancialMonthly = () => import ('@/views/financial/MonthlyKPI');
const FinancialConsolidated = () => import ('@/views/financial/ConsolidatedKPI');
const FinancialWalkerPaymentCalculator = () => import ('@/views/financial/WalkerPaymentCalculator');
//Coupons
const CouponsDashboard = () => import ('@/views/coupons/Dashboard');
Vue.use(Router)

let routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Inicio',
    component: TheContainer,
    children: [
      {
        path: 'dashboard',
        // name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true
        },       
      },     
      {
        path: 'theme',
        redirect: '/theme/colors',
        name: 'Theme',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'colors',
            name: 'Colors',
            component: Colors
          },
          {
            path: 'typography',
            name: 'Typography',
            component: Typography
          }
        ]
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'tables',
        redirect: '/tables/tables',
        name: 'Tables',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'tables',
            name: 'Basic tables',
            component: Tables
          },
          {
            path: 'advanced-tables',
            name: 'Advanced tables',
            component: AdvancedTables
          }
        ]
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      },
      {
        path: 'users',
        // meta: { label: 'Users'},
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'profile',
            name: 'users-profile',
            component: UserProfile,            
            meta: {
              label: 'Mi perfil',
              requiresAuth: true
            }
          },
          {
            path: 'create',
            name: 'Perfil',
            component: UserCreate,
            meta: {
              label: 'Perfil',
              requiresAuth: true
            }
          },
          {
            path: '',
            name: 'Users',
            component: Users
          },
          {
            path: ':id',
            meta: {
              label: 'User Details'
            },
            name: 'User',
            component: User
          }
        ]
      },     
      {
        path: 'providers',
        redirect: '/providers/dashboard',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'dashboard',    
            component: DashboardProvider,
            name: 'provider-dashboard',
            meta: {
              label: 'Dashboard',
              requiresAuth: true
            },              
          },
          {
            path: 'detalle',    
            component: WalkerServices,
            name: 'provider-services-table',
            meta: {
              label: 'Dashboard',
              requiresAuth: true
            },              
          },
          {
            path: 'users',
            // redirect: '/apps/invoicing/invoice',
            // name: 'providers-users',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'create',
                name: 'providers-users-create',
                component: ProviderCreate,
                meta: {
                  label: 'Perfil',
                  requiresAuth: true
                }
              },
            ]
          },
          {
            path: 'calendar',    
            name: 'provider-calendar',
            component: ProviderCalendar,            
            meta: {
              label: 'Agenda',
              requiresAuth: true
            },              
          },
          {
            path: 'list',    
            component: ProviderCalendarList,
            name: 'provider-calendar-list',
            meta: {
              label: 'Lista',
              requiresAuth: true
            },              
          },
          {
            path: 'schedule',    
            name: 'provider-schedule',
            component: ProviderSchedule,            
            meta: {
              label: 'Mi horario',
              requiresAuth: true
            },              
          },
        ]
      },
      {
        path: 'pets',
        // meta: { label: 'Mascotas' },        
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          // {
          //   path: 'create',
          //   name: 'Create',
          //   component: PetsCreate,
          //   meta: {
          //     label: 'Mis mascotas',
          //     requiresAuth: true
          //   }
          // },          
          {
            path: 'index',
            name: 'pets-index',
            component: PetsIndex,
            meta: {
              label: 'Mis mascotas',
              requiresAuth: true
            }
          },                           
          {
            path: 'form/:petId',
            props: true,
            name: 'Form',
            component: PetsForm,
            meta: {
              label: 'Formulario',
              requiresAuth: true
            }
          },                           
        ]
      },
      {
        path: 'base',
        redirect: '/base/cards',
        name: 'Base',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'breadcrumbs',
            name: 'Breadcrumbs',
            component: Breadcrumbs
          },
          {
            path: 'cards',
            name: 'Cards',
            component: Cards
          },
          {
            path: 'carousels',
            name: 'Carousels',
            component: Carousels
          },
          {
            path: 'collapses',
            name: 'Collapses',
            component: Collapses
          },
          {
            path: 'jumbotrons',
            name: 'Jumbotrons',
            component: Jumbotrons
          },
          {
            path: 'list-groups',
            name: 'List Groups',
            component: ListGroups
          },
          {
            path: 'navs',
            name: 'Navs',
            component: Navs
          },
          {
            path: 'navbars',
            name: 'Navbars',
            component: Navbars
          },
          {
            path: 'paginations',
            name: 'Paginations',
            component: Paginations
          },
          {
            path: 'popovers',
            name: 'Popovers',
            component: Popovers
          },
          {
            path: 'progress-bars',
            name: 'Progress Bars',
            component: ProgressBars
          },
          {
            path: 'switches',
            name: 'Switches',
            component: Switches
          },
          {
            path: 'tabs',
            name: 'Tabs',
            component: Tabs
          },
          {
            path: 'tooltips',
            name: 'Tooltips',
            component: Tooltips
          }
        ]
      },
      {
        path: 'buttons',
        redirect: '/buttons/standard-buttons',
        name: 'Buttons',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'standard-buttons',
            name: 'Standard Buttons',
            component: StandardButtons
          },
          {
            path: 'button-groups',
            name: 'Button Groups',
            component: ButtonGroups
          },
          {
            path: 'dropdowns',
            name: 'Dropdowns',
            component: Dropdowns
          },
          {
            path: 'brand-buttons',
            name: 'Brand Buttons',
            component: BrandButtons
          },
          {
            path: 'loading-buttons',
            name: 'Loading Buttons',
            component: LoadingButtons
          }
        ]
      },
      {
        path: 'editors',
        redirect: '/editors/text-editors',
        name: 'Editors',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'text-editors',
            name: 'Text Editors',
            component: TextEditors
          },
          {
            path: 'code-editors',
            name: 'Code Editors',
            component: CodeEditors
          }
        ]
      },
      {
        path: 'forms',
        redirect: '/forms/basic-forms',
        name: 'Forms',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'basic-forms',
            name: 'Basic Forms',
            component: BasicForms
          },
          {
            path: 'advanced-forms',
            name: 'Advanced Forms',
            component: AdvancedForms
          },
          {
            path: 'validation-forms',
            name: 'Form Validation',
            component: ValidationForms
          },
          {
            path: 'multi-select',
            name: 'Multiselect',
            component: MultiSelect
          },
        ]
      },
      {
        path: 'google-maps',
        name: 'Google Maps',
        component: GoogleMaps
      },
      {
        path: 'icons',
        redirect: '/icons/font-awesome',
        name: 'Icons',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'coreui-icons',
            name: 'CoreUI Icons',
            component: CoreUIIcons
          },
          {
            path: 'flags',
            name: 'Flags',
            component: Flags
          },
          {
            path: 'brands',
            name: 'Brands',
            component: Brands
          }
        ]
      },
      {
        path: 'notifications',
        redirect: '/notifications/alerts',
        name: 'Notifications',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'alerts',
            name: 'Alerts',
            component: Alerts
          },
          {
            path: 'badges',
            name: 'Badges',
            component: Badges
          },
          {
            path: 'modals',
            name: 'Modals',
            component: Modals
          },
          {
            path: 'toaster',
            name: 'Toaster',
            component: Toaster
          }
        ]
      },
      {
        path: 'plugins',
        redirect: '/plugins/draggable',
        name: 'Plugins',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'draggable',
            name: 'Draggable Cards',
            component: Draggable
          },
          {
            path: 'calendar',
            name: 'Calendar',
            component: Calendar
          },
          {
            path: 'spinners',
            name: 'Spinners',
            component: Spinners
          }
        ]
      },
     
      {
        path: 'apps',
        name: 'Apps',
        redirect: '/apps/invoicing/invoice',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'invoicing',
            redirect: '/apps/invoicing/invoice',
            name: 'Invoicing',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'invoice',
                name: 'Invoice',
                component: Invoice
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/apps/email',
    redirect: '/apps/email/inbox',
    name: 'Email',
    component: EmailApp,
    children: [{
        path: 'compose',
        name: 'Compose',
        component: Compose
      },
      {
        path: 'inbox',
        name: 'Inbox',
        component: Inbox
      },
      {
        path: 'message',
        name: 'Message',
        component: Message
      }
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    meta: {
      label: 'General',
      requiresAuth: false
    },
    component: TheContainer,   
    children: [
      {
        path: '404',
        name: 'Page404',
        component: Page404
      },
      {
        path: '500',
        name: 'Page500',
        component: Page500
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          noAuthpage: true
        }
      },
      {
        path: 'register/',        
        name: 'Register',
        component: Register,
        meta: {
          noAuthpage: true
        }
      },
      {
        path: 'main/',
        name: 'Main',
        component: Main,
        meta: {
          noAuthpage: true
        }
      },
      {
        path: 'termsdashboard',        
        component: TermsDashboard,
        meta: {          
          requiresAuth: false
        }
      },
      {
        path: 'terms',        
        component: TermsAndConditions,
        meta: {          
          requiresAuth: false
        }
      },
      {
        path: 'termsprovider',
        name: 'Términos y condiciones proveedor',
        component: TermsAndConditionsProvider,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'personaldata',        
        component: TermsPersonalData,
        meta: {          
          requiresAuth: false,
        }
      },
      {
        path: 'support',
        // name: 'Soporte',
        component: Support,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'termstag',
        name: 'TermsAndConditionsTags',
        component: TermsAndConditionsTag,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'qr',
        name: 'QRGenerator',
        component: QRGenerator,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'passrecovery',
        name: 'Password',
        component: PassRecovery,
        meta: {
          noAuthpage: true
        }
      },
      {
        path: 'walker',
        name: 'Aplicar como paseador',
        component: WalkerApply,
        meta: {
          requiresAuth: false
        }
      },
    ],
  },
  {
    path: '/public',
    redirect: '/pages/main',
    name: 'Public',
    meta: {
      label: 'Público',
      requiresAuth: false
    },
    component: TheContainer,   
    children: [            
      {
        path: 'pet/:id',
        name: 'Profile',
        component: PetProfileQR, 
        props:true,       
        meta: {
          label: 'Perfil de mascota',                        
        }
      },
    ],    
  },
  {
    path: '/p',
    redirect: '/pages/main',
    name: 'Public',
    meta: {
      label: 'Público',
      requiresAuth: false
    },
    component: TheContainer,   
    children: [            
      {
        path: ':id',
        name: 'Profile',
        component: PetProfileQR, 
        props:true,       
        meta: {
          label: 'Perfil de mascota',                        
        }
      },
    ],    
  },
 
  {
    path: '/services',
    redirect: '/pages/main',
    // name: 'service_detail',
    component: TheContainer,    
    meta: {
      // label: 'Detalle de servicio',
      requiresAuth: false
    },
    children: [
      {
        path: 'profile/:serviceId',
        // path: 'profile/:serviceData',
        name: 'Detalle de servicio',
        component: ServiceProfile,
        props: true,
        meta: {          
          requiresAuth: false
        }
      },      
      {
        path: 'payments',        
        name: 'Pagos',
        component: Payments,        
        meta: {
          label: 'Pagos',
          requiresAuth: true
        }
      },      
      {
        path: 'schedule/:id',        
        name: 'Programaciones',
        component: Schedule,        
        meta: {
            requiresAuth: true
        }
      },      
      {
        path: 'gps',        
        name: 'Seguimiento',
        // redirect: '/pages/login',
        component: TrackingGPS,        
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'buy',        
        name: 'Comprar',
        component: Buy,        
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'payment_confirmation',        
        name: 'Confirmación de pago',
        component: PaymentConfirmation,        
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'qualify',
        name: 'service-qualification',
        component: ServiceQualification,
        meta: {
          requiresAuth: false,
        }
      },
      {
        path: 'index',
        name: 'services-index',
        component: ServicesIndex,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'quotation',
        name: 'service-quotation',
        component: ServiceQuotation,
        meta: {
          requiresAuth: false,
        }
      },
    ]
  },
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    name: 'admin',
    component: TheContainer,     
    meta: {
      label: 'Admin',
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',        
        name: 'admin-dashboard',
        component: AdminDashboard,        
        meta: {
          requiresAuth: true
        }
      },      
      {
        path: 'execution',        
        name: 'admin-execution',
        component: AdminExecution,        
        meta: {
          requiresAuth: true
        }
      },      
      {
        path: 'clients',        
        name: 'admin-clients',
        component: AdminClients,        
        meta: {
          requiresAuth: true
        }
      },      
      {
        path: 'clients-type',        
        name: 'admin-clients-activity',
        component: AdminClientsActivity,        
        meta: {
          requiresAuth: true
        }
      },      
      {
        path: 'providers-distance',        
        name: 'admin-providers-distance',
        component: AdminProvidersDistance,        
        meta: {
          requiresAuth: true
        }
      },      
      {
        path: 'calendar',        
        name: 'admin-calendar',
        component: AdminCalendar,        
        meta: {
          requiresAuth: true
        }
      },      
      {
        path: 'fix-order-count',        
        name: 'admin-owner-fixcountleft',
        component: FixOrdersCount,        
        meta: {
          requiresAuth: true
        }
      },      
    ]
  },
  {
    path: '/financial',
    redirect: '/financial/dashboard',
    name: 'financial',
    component: TheContainer,     
    meta: {
      label: 'Financial',
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',        
        name: 'financial-dashboard',
        component: FinancialDashboard,        
        meta: {
          requiresAuth: true
        }
      },   
      {
        path: 'payments',        
        name: 'Pagos',
        component: FinancialPayments,        
        meta: {
          label: 'Pagos',
          requiresAuth: true
        }
      },  
      {
        path: 'create_payment',
        name: 'create-payment',
        component: FinancialPaymentCreate,
        meta: {
          label: 'Crear pago',
          requiresAuth: true
        }
      }, 
      {
        path: 'pending-payments',        
        name: 'pending-payments',
        component: FinancialPendingPayments,        
        meta: {
          label: 'Pagos pendientes',
          requiresAuth: true
        }
      },  
      {
        path: 'monthly',        
        name: 'financial-monthly',
        component: FinancialMonthly,        
        meta: {
          requiresAuth: true
        }
      },           
      {
        path: 'consolidated',        
        name: 'financial-consolidated',
        component: FinancialConsolidated,        
        meta: {
          requiresAuth: true
        }
      },           
      {
        path: 'walker-payment-calculator',
        name: 'walker-payment-calculator',
        component: FinancialWalkerPaymentCalculator,        
        meta: {
          requiresAuth: true
        }
      },           

    ]
  },
  {
    path: '/coupons',
    redirect: '/dashboard',
    name: 'coupons',
    component: TheContainer,
    meta: {
      label: 'Cupones',
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'coupons-dashboard',
        component: CouponsDashboard,
        meta: {
          label: 'Tablero descuentos',
          requiresAuth: true
        }
      }
    ],
  }
]

let router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const noAuthpage = to.matched.some(record => record.meta.noAuthpage)
  const isCurrentUser = currentUser != null ? true : false
  const currentUserId = currentUser != null ? currentUser.uid : ''

  if (!isCurrentUser && requiresAuth){
    await firebase.auth().signOut()
    next({path: '/pages/main', replace: true})
  } else if (isCurrentUser && noAuthpage){
    // console.log('go to dashboard')
    next({path: '/dashboard', replace: true})   
  } else  {
    // console.log('next')
    next()    
  }
});

export default router;
